import firebase from 'firebase/app';
import 'firebase/firestore';

 // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: "AIzaSyCFfzUPQxhXU9UKEx5BPjE16c7KzOb6XEc",
    authDomain: "link-share-7f067.firebaseapp.com",
    projectId: "link-share-7f067",
    storageBucket: "link-share-7f067.appspot.com",
    messagingSenderId: "961738496645",
    appId: "1:961738496645:web:65e2daf94ad58b952f27ec"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  export default firebase;